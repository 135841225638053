import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'

import { getFeatureFlagForCurrentUser } from '../api-client/routes/featureFlags'

export type FeatureFlags =
  | 'ENABLE_ADMIN_CREATE_SERVICE_ORDERS'
  | 'ENABLE_ADMIN_SEARCH_MODAL'
  | 'ENABLE_ADMIN_ROUTES'
  | 'ENABLE_PRICED_TIMESLOTS'
  | 'ENABLE_BRIDGE_QR_CODE_TESTER_VIEW'
  | 'ENABLE_BRIDGE_PUT_AWAY'
  | 'ENABLE_PICKING_HANDOVERS'
  | 'ENABLE_BRIDGE_ROUTE_TIMING'
  | 'ENABLE_BRIDGE_PICKING'
  | 'ENABLE_ADMIN_ITEMS_MISSING_LOCATION'
  | 'ENABLE_BRIDGE_QUARANTINE'
  | 'ENABLE_BRIDGE_SERVICE_ORDERS'
  | 'ENABLE_PRICE_GROUP_ORDERLINES'
  | 'ENABLE_INBOUD_TRIAGE'
  | 'ENABLE_ADMIN_ROUTE_PLANNING'
  | 'ENABLE_OPS_TIMESLOTS'
  | 'ENABLE_RFID'
  | 'ENABLE_REFRESH_HANDOVERS'
  | 'ENABLED_TABBED_HANDOVERS'
  | 'ENABLE_CUSTOM_ROUTE_STOP_SMS'
  | 'ENABLE_OUTBOUND'
  | 'ENABLE_BULK_LOCATION_UPDATE'
  | 'ENABLE_BRIDGE_PICKING_R1'
  | 'ENABLE_ZEBRA_PRINT'
  | 'ENABLE_INVENTORY_VIEW'
  | 'ENABLE_CONFETTI'
  | 'ENABLE_BRIDGE_SERVICE_OUTBOUND'
  | 'ENABLE_CONSIGNMENTS'
  | 'ENABLE_PUTWAY_WAREHOUSE'
  | 'ENABLE_SERVICE_INBOUND'
  | 'ENABLE_TRANSLATION_FROM_DB'
  | 'ENABLE_ADMIN_ACCOUNTS_VIEW'
  | 'ENABLE_ITEM_SCANNER_BEEP'
  | 'ENABLE_PAY_PER_AREA_PICKUP_ITEMS'
  | 'ENABLE_ADMIN_WAREHOUSE_VISIT'
  | 'ENABLE_CUSTOM_TAAS_ORDERLINE'
  | 'ENABLE_STORAGE_UNIT_ORDERS'

const isLocalhost =
  typeof window !== 'undefined' ? window.location.hostname.includes('local') : true

const defaults: Record<FeatureFlags, boolean> = {
  ENABLE_ADMIN_CREATE_SERVICE_ORDERS: isLocalhost,
  ENABLE_ADMIN_SEARCH_MODAL: isLocalhost,
  ENABLE_ADMIN_ROUTES: isLocalhost,
  ENABLE_PRICED_TIMESLOTS: isLocalhost,
  ENABLE_BRIDGE_QR_CODE_TESTER_VIEW: isLocalhost,
  ENABLE_BRIDGE_PUT_AWAY: isLocalhost,
  ENABLE_PICKING_HANDOVERS: isLocalhost,
  ENABLE_BRIDGE_ROUTE_TIMING: isLocalhost,
  ENABLE_BRIDGE_PICKING: isLocalhost,
  ENABLE_ADMIN_ITEMS_MISSING_LOCATION: isLocalhost,
  ENABLE_BRIDGE_QUARANTINE: isLocalhost,
  ENABLE_BRIDGE_SERVICE_ORDERS: isLocalhost,
  ENABLE_PRICE_GROUP_ORDERLINES: isLocalhost,
  ENABLE_INBOUD_TRIAGE: isLocalhost,
  ENABLE_ADMIN_ROUTE_PLANNING: isLocalhost,
  ENABLE_OPS_TIMESLOTS: isLocalhost,
  ENABLE_RFID: isLocalhost,
  ENABLE_REFRESH_HANDOVERS: isLocalhost,
  ENABLED_TABBED_HANDOVERS: isLocalhost,
  ENABLE_CUSTOM_ROUTE_STOP_SMS: isLocalhost,
  ENABLE_OUTBOUND: isLocalhost,
  ENABLE_BULK_LOCATION_UPDATE: isLocalhost,
  ENABLE_BRIDGE_PICKING_R1: isLocalhost,
  ENABLE_ZEBRA_PRINT: false,
  ENABLE_INVENTORY_VIEW: isLocalhost,
  ENABLE_CONFETTI: isLocalhost,
  ENABLE_BRIDGE_SERVICE_OUTBOUND: isLocalhost,
  ENABLE_CONSIGNMENTS: isLocalhost,
  ENABLE_PUTWAY_WAREHOUSE: isLocalhost,
  ENABLE_SERVICE_INBOUND: isLocalhost,
  ENABLE_TRANSLATION_FROM_DB: isLocalhost,
  ENABLE_ADMIN_ACCOUNTS_VIEW: isLocalhost,
  ENABLE_ITEM_SCANNER_BEEP: isLocalhost,
  ENABLE_PAY_PER_AREA_PICKUP_ITEMS: isLocalhost,
  ENABLE_ADMIN_WAREHOUSE_VISIT: isLocalhost,
  ENABLE_CUSTOM_TAAS_ORDERLINE: isLocalhost,
  ENABLE_STORAGE_UNIT_ORDERS: isLocalhost,
}

export function useFeatureFlags(options: { suspense?: boolean } = {}) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { data, ...rest } = useQuery(
    ['feature-flags-for-user'],
    async () => {
      const flags = await getFeatureFlagForCurrentUser({ token: await getAccessTokenSilently() })
      return { ...defaults, ...flags }
    },
    { enabled: isAuthenticated, suspense: true, ...options },
  )
  return { featureFlags: { ...defaults, ...data }, ...rest }
}
