import type { CreateTranslationDto, TranslationResponseDto } from '@wanda-space/types'
import { request } from '../api-client'
import { type ApiFilters, fetchChunk } from '../chunk'

export async function getTranslations(language: string): Promise<Record<string, string>> {
  const params = new URLSearchParams({ projects: 'products', noCache: 'true' })
  params.append('projects', 'spaceship')
  params.append('projects', 'haku')
  return request<Record<string, string>>({
    method: 'GET',
    url: `translations/${language}`,
    params,
    token: undefined,
  })
}

export async function getHakuTranslations(language: string): Promise<Record<string, string>> {
  return request<Record<string, string>>({
    method: 'GET',
    url: `translations/${language}`,
    params: new URLSearchParams({ projects: 'products', noCache: 'true' }),
    token: undefined,
  })
}

export async function fetchTranslationList(params: {
  orderBy: string
  orderDir: 'ASC' | 'DESC'
  itemsPerPage: number
  page: number
  search: string | undefined
  filters: ApiFilters | undefined
  token: string
}) {
  return fetchChunk<TranslationResponseDto>({
    ...params,
    search: params.search?.trim(),
    url: 'admin/translations',
  })
}

export async function createTranslation(data: CreateTranslationDto, token: string) {
  return request<TranslationResponseDto>({
    method: 'POST',
    url: 'admin/translations',
    body: data,
    token,
  })
}

export async function updateTranslation(id: string, data: CreateTranslationDto, token: string) {
  return request<TranslationResponseDto>({
    method: 'PUT',
    url: `admin/translations/${id}`,
    body: data,
    token,
  })
}
